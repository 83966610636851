import React from 'react'
import axios from 'axios'
import { useNavigate, Link  } from 'react-router-dom';

export default function Sidebar() {


  const logindata = JSON.parse(localStorage.getItem('login'));
  let A = logindata.account.name;
  return (
    <div>
      
      <div className="navbar-expand-lg navbar-light">
           <div id="sidebarNav" className="collapse navbar-collapse navbar-vertical">
           
             <div className="card flex-grow-1 mb-5" style={{border:'solid 2px #ccc'}}>
               <div className="card-body">
            
                 <div className="d-none d-lg-block text-center mb-5">
                   <div className="avatar avatar-xxl avatar-circle mb-3">
                     <img className="avatar-img" src={"https://ui-avatars.com/api/?name="+A+"&color=7F9CF5&background=EBF4FF"} alt="Image Description"/>
                     <img className="avatar-status avatar-lg-status" src="assets/svg/illustrations/top-vendor.svg" alt="Image Description" data-bs-toggle="tooltip" data-bs-placement="top" title="Verified user"/>
                   </div>

                   <h4 className="card-title mb-0">{logindata.account.name}</h4>
                   <p className="card-text small">{logindata.account.company}</p>
                 </div>
             

         
               

               
                 <ul className="nav nav-sm nav-tabs nav-vertical mb-4">
                 <li className="nav-item">
                     <Link to="/dashboard" className="nav-link " >
                       <i className="bi-house nav-icon"></i> Dashboard
                     </Link>
                   </li>
                   
                   <li className="nav-item">
                     <Link to="/changepassword" className="nav-link " >
                       <i className="bi-lock nav-icon"></i> Change Password
                     </Link>
                   </li>
                 
                  
                 </ul>


                 <div className="text-cap">Reports</div>

               
                <ul className="nav nav-sm nav-tabs nav-vertical mb-4">
                  <li className="nav-item">
                    <Link className="nav-link " to="/reports">
                      <i className="bi-cash-coin nav-icon"></i> Leads
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link " to="/packout">
                      <i className="bi-cash-coin nav-icon"></i> Packout
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link " to="/packback">
                      <i className="bi-cash-coin nav-icon"></i> Packback
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link " to="/ip1">
                      <i className="bi-cash-coin nav-icon"></i> IP1
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link " to="/ip2">
                      <i className="bi-cash-coin nav-icon"></i> IP2
                    </Link>
                  </li>
                 
                 
                </ul>
               

            
                  {logindata.account.isAdmin ?  <>   
                 <div className="text-cap">Admin Manager</div>

      
                 <ul className="nav nav-sm nav-tabs nav-vertical">
                   <li className="nav-item">
                     <Link className="nav-link " to="/admin">
                       <i className="bi-person nav-icon"></i> Admin Manager
  
                     </Link>
                   </li>
                   {logindata.account.csAdmin ?  <>  
                   <li className="nav-item">
                     <Link className="nav-link " to="/introducers">
                       <i className="bi-people nav-icon"></i> Super Admin Access
  
                     </Link>
                   </li>   
                   </> : <></>}   
                                  
                 </ul>
              
                 </> : <></>}

                 
                 
     

                 <div className="d-lg-none">
                   <div className="dropdown-divider"></div>

             
                   <ul className="nav nav-sm nav-tabs nav-vertical">
                    
                   </ul>
            
                 </div>
      
               </div>
             </div>
  
           </div>
       
   
       </div>

    </div>
  )
}
