import React,{ useState} from 'react'

import Login from './screen/Login';

import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation  } from 'react-router-dom';
import Header from './components/Header';
import Dashboard from './screen/Dashboard';
import Admins from './screen/Admins';
import Reports from './screen/Reports';
import Packout from './screen/Packout';
import Packback from './screen/Packback';
import Ip1 from './screen/Ip1';
import Changepassword from './screen/Changepassword';
import AuthRoutes from './routes/Authroutes';
import Ip2 from './screen/Ip2';
import Introducers from './screen/Introducers';
import Introducerusers from './screen/Introducerusers';

function App() {

  const apidata = JSON.parse(localStorage.getItem('login'));

  return (
    <Router>
      <Header apidata={apidata}></Header>
      <div className="App">
      <Routes>
       <Route  path='/' element={<AuthRoutes />}>           
          <Route  exact path='/' element={<Dashboard />} /> 
          <Route  exact path='/dashboard' element={<Dashboard />} /> 
          <Route  exact path='/admin' element={<Admins />} /> 
          <Route  exact path='/reports' element={<Reports />} /> 
          <Route  exact path='/packout' element={<Packout />} /> 
          <Route  exact path='/packback' element={<Packback />} /> 
          <Route  exact path='/ip1' element={<Ip1 />} /> 
          <Route  exact path='/ip2' element={<Ip2 />} /> 
          <Route  exact path='/changepassword' element={<Changepassword />} /> 
          <Route  exact path='/introducers' element={<Introducers />} /> 
          <Route  exact path='/introducerusers:introid' element={<Introducerusers />} /> 
         </Route>     
         <Route  exact path='/login' element={<Login />} />   
      </Routes>      
        
       </div>
   </Router>
  );
}

export default App;
