import React, {useState, useEffect} from 'react'
import axios from 'axios';
import Sidebar from '../components/Sidebar'
import { useNavigate, Link  } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function Introducers() {

  const [admindata, setadmindata]= useState(null);


  const [dataloading, setdataloading]= useState(false);

  const [loading, setloading]= useState(false);
  const [createsuccess, setcreatesuccess]= useState(false);
  const [editsuccess, seteditsuccess]= useState(false);
  const [deletesuccess, setdeletesuccess]= useState(false);
  const [mismatch, setmismatch]= useState(false);
  
  const [name, setname]= useState(null);
  const [email, setemail]= useState(null);
  const [password, setpassword]= useState(null);
  const [admin, setadmin]= useState(null);
  const [accountID, setaccountID]= useState(null);

  const navigate = useNavigate();

  const logindata = JSON.parse(localStorage.getItem('login'));

  const config = {
    headers: { Authorization: `Bearer ${logindata.token}` }
  };   
   

  useEffect(() => {
    console.log("working");
    setdataloading(true);
        axios.get(`https://clearstart-api.clientmaster.co.uk/api/introducers/cs-admin/list/`,config).then((response) => {  
          setadmindata(response.data);
          console.log(response.data);
          setdataloading(false);
      }).catch((error) => {  
        alert('session timeout please re-login and try again')
            console.log('login timeout');
            localStorage.clear();      
            navigate('/login');          
      }); 


  
  },[])

  

 



  return (
    <div>

    <div className="navbar-dark bg-dark" style={{   backgroundImage: `url("assets/svg/components/wave-pattern-light.svg")` }} >
          <div className="container content-space-1 content-space-b-lg-3">
            <div className="row align-items-center">
              <div className="col">
                <div className="d-none d-lg-block">
                  <h1 className="h2 text-white">Introducer Manager</h1>
                </div>
    
            
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light mb-0">
                    <li className="breadcrumb-item"> Manage Introducers</li>
                   
                  </ol>
                </nav>
               
              </div>
           
    
              <div className="col-auto">
                <div className="d-none d-lg-block">
                 
                </div>
    
             
                <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarNav" aria-controls="sidebarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <div className="navbar-toggler-default">
                    <i className="bi-list"></i>
                  </div>
                  <div className="navbar-toggler-toggled">
                    <i className="bi-x"></i>
                  </div>
                </button>
               
              </div>
            
            </div>
           
          </div>
        </div>
        <div className="container  content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className='row'>
    
    
            <div className="col-lg-3">
            <Sidebar />
            </div> 

        <div className="col-lg-9">
          <div className="d-grid gap-3 gap-lg-5">
          <div className="card">
              <div className="card-header border-bottom">
             
                <h4 className="card-header-title">List of Introducers</h4>
               
              </div>             
             

        
              {createsuccess? <>
                <div className="alert alert-success" role="alert">
                 New User Account Added
                </div>
                </>: <></>}

                {editsuccess? <>
                <div className="alert alert-success" role="alert">
                User Account Modified Successfully
                </div>
                </>: <></>}

                {deletesuccess? <>
                <div className="alert alert-success" role="alert">
                User Removed Successfully
                </div>
                </>: <></>}

                    
              <div className="table-responsive">
              {dataloading?<><Skeleton count={8} /> </>              
              :<>
                <table className="table table-borderless table-thead-bordered table-nowrap table-align-middle">
                  <thead className="thead-light">
                    <tr>
                      <th>ID</th>
                      <th>Name</th>                      
                      <th>Status</th>             
                      <th>Users</th>             
                      <th></th>      
                      <th></th>                   
                    
                    </tr>
                  </thead>
           
                   <tbody>

             {admindata?<>
              {admindata.map(data => {                        
                      return (          
                    <tr key={data.cmv2} >
                    <td>{data.cmv2}</td> 
                      <td>{data.name}</td>           
                      <td>{data.active}</td>        
                      <td>{data.users}</td>               
                      <td>
                      <Link className="btn btn-white btn-xs" to={`/introducerusers${data.cmv2}`} >  
                        <i className="bi-pencil-fill me-1"></i> Manage
                      </Link>
                    
                        </td>                 
                      
                    </tr>
                    
                    )
                  })} 
                  </>:<></>}
                  
                  </tbody>      
                       
                </table> 
                </> }                
              </div>
            

              </div>

            
          </div>
        </div> 
      </div>

    </div>     

     
  </div>
  )
}
