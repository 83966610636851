import React, {useState, useEffect} from 'react'
import { CSVLink, CSVDownload } from "react-csv";
import axios from 'axios';
import Sidebar from '../components/Sidebar'
import { useNavigate, Link  } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function Reports() {
  const navigate = useNavigate();
  const [admindata, setadmindata]= useState(null);
  const [sortdate, setsortdate]= useState(null);
  const logindata = JSON.parse(localStorage.getItem('login'));
  const [loading, setloading]= useState(true);

  const config = {
    headers: { Authorization: `Bearer ${logindata.token}` }
  };   
  useEffect(() => {    
    axios.get(`https://clearstart-api.clientmaster.co.uk/api/introducers/report/leads/`,config).then((response) => {  
      setadmindata(response.data.data);
      setloading(false);
  }).catch((error) => {  
    alert('session timeout please re-login and try again')
        console.log('login timeout');
        localStorage.clear();      
        navigate('/login');          
  }); 
},[])



const sortdata = (sortdate) =>{
  console.log("Sort Data");
  setloading(true); 
  axios.get(`https://clearstart-api.clientmaster.co.uk/api/introducers/report/leads/?start=`+sortdate,config).then((response) => {  
    setadmindata(response.data.data);
      setloading(false);
  });
  
}

if(loading===false)
{
  admindata.forEach(o=>delete o.IP1);
  admindata.forEach(o=>delete o.IP2);
  admindata.forEach(o=>delete o.IP3);
}

  return (
    <div>

    <div className="navbar-dark bg-dark" style={{   backgroundImage: `url("assets/svg/components/wave-pattern-light.svg")` }} >
          <div className="container content-space-1 content-space-b-lg-3">
            <div className="row align-items-center">
              <div className="col">
                <div className="d-none d-lg-block">
                  <h1 className="h2 text-white">Reports Manager</h1>
                </div>
    
            
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light mb-0">
                    <li className="breadcrumb-item">Reports - Leads</li>
                   
                  </ol>
                </nav>
               
              </div>
           
    
              <div className="col-auto">
                <div className="d-none d-lg-block">
                  <input type="date" className='form-control' onChange={(e)=> sortdata(e.target.value)} />
                </div>
    
             
                <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarNav" aria-controls="sidebarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <div className="navbar-toggler-default">
                    <i className="bi-list"></i>
                  </div>
                  <div className="navbar-toggler-toggled">
                    <i className="bi-x"></i>
                  </div>
                </button>
               
              </div>
            
            </div>
           
          </div>
        </div>
        <div className="container  content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className='row'>
    
    
            <div className="col-lg-3">
            <Sidebar />
            </div> 

        <div className="col-lg-9">
          <div className="d-grid gap-3 gap-lg-5">
          <div className="card">
              <div className="card-header border-bottom">
                <h4 className="card-header-title">Reports - Leads</h4>
              </div>             
             


              {loading?<><Skeleton count={8} /> </>              
              :<>      
              <div className="table-responsive">
              <CSVLink className='btn btn-sm ' style={{float:'right',}} data={admindata}>Download CSV</CSVLink>
                <table className="table table-borderless table-thead-bordered table-nowrap table-align-middle">
                  <thead className="thead-light">
                    <tr>
                      <th>ID</th>
                      <th>Name</th>                   
                      <th>Sales Person</th>
                      <th>Pack Sent</th>
                      <th>Pack Back</th>
                      <th>Payment Due</th>                      
                      <th>DI</th>       
                      <th>IP1</th>       
                      <th>IP2</th>                        
                    </tr>
                  </thead>

                  <tbody>

            {admindata.map(data => {                        
                return (          
              <tr key={data.CaseID} >                   
                <td>{data.CaseID}</td>           
                <td>{data.CustomerName}
                <br/><small>{data.PostalAddress}</small></td>        
               
                <td>{data.SalesAdvior}</td>
                <td>{data.InfomationPack}</td>
                <td>{data.LOA}</td>
                <td>{data.NextPayment}</td>
                <td>{data.DI}</td>
                <td>{data.IP1}</td>
                <td>{data.IP2}</td>            
            
              </tr>
              )
            })}
                  
                  </tbody>
                </table>
              </div>
              </>}




              </div>

            
          </div>
        </div> 
      </div>

    </div>     

     
  </div>
  )
}
