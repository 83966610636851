import React, {useState, useEffect} from 'react'
import axios from 'axios';
import { useNavigate, Link  } from 'react-router-dom';

export default function Login() {
  const [username, setemail]= useState('');
  const [password, setpassword]= useState('');
  const [ClientID, setclientid]= useState('');
  const [loginstat, setLoginstat]= useState(false);
  const [invalidlogin, setinvalidlogin]= useState(false);
 // const [token, setToken]= useState('');

  const navigate = useNavigate();
  const [loading, setloading]= useState(false);
  const [forgot, setforgot]= useState(false);

  const sendlogin = () =>{
    console.log("login Trying");
    setloading(true);
    axios.post('https://clearstart-api.clientmaster.co.uk/api/introducers/token',{username,password}).then((response) =>{   
     let userdata = JSON.stringify(response.data);

   
  console.log("login successfull");
   if(response.data.sucess)
      {
        console.log('logged');   
      
        localStorage.setItem('login', userdata);    


          navigate('/dashboard');  
         // window.location.reload() 
          setloading(false);
         
            

           
      }else
      {
      
        console.log('invalied')
          setinvalidlogin(true);
          setloading(false);
        
      } 
      

  
   
    }        
  ).catch((error) => {  
    setinvalidlogin(true);
    setLoginstat(true);
    setloading(false);
  });
}



const passwordreset = () =>{
  console.log("password reset");
  setloading(true); 
  axios.post('https://clearstart-api.clientmaster.co.uk/api/introducers/forgot-password',{email:username}).then((response) =>{   
  console.log(response);
  setforgot(false);
  setloading(false);
  }        
  ).catch((error) => {  
    setLoginstat(true);
    setloading(false);
  });
  
}


  return (
    <div>
     


     
<div className="container content-space-2 content-space-lg-1">
  <div className="row justify-content-lg-between align-items-md-center">
    
   

    <div className="col-md-7 col-lg-6">
  
<div className="container content-space-2 content-space-lg-3">
  <div className="flex-grow-1 mx-auto" style={{Width:'28rem'}}>
   
    <div className="text-center mb-5 mb-md-7">
      <h1 className="h2">Introducer Portal Login</h1>
      <p>Login to manage your account.</p>
    </div>


    <form className="js-validate needs-validation" noValidate>
        {invalidlogin? <>
          <div className="alert alert-warning" role="alert">
            Error Login. Please check your credentials!
          </div>
          </>: <>
          
          </>}
        <div className="mb-4">
          <label className="form-label" htmlFor="signupSimpleLoginEmail">Your email</label>
          <input type="email"  onChange={(e)=> setemail(e.target.value)}  className="form-control form-control-lg" name="email" id="signupSimpleLoginEmail" placeholder="email@site.com" aria-label="email@site.com" required />
          <span className="invalid-feedback">Please enter a valid email address.</span>
        </div>


     

       {forgot?<>
       
        <div className="d-grid mb-3">
        <div className="d-flex justify-content-between align-items-center">
                  <label class="form-label" htmlFor="signupModalFormLoginPassword"></label>

                  <a class="form-label-link" href='#' onClick={(e)=> setforgot(false)} >Back to login</a>
                </div>

          <button type="button" onClick={passwordreset} style={{backgroundColor:'#08605F'}} className="btn btn-success btn-lg" disabled={loading}>{loading?'Please Check Your Email':'Reset Password'}</button>
        </div>
         </> :
       <>
        <div className="mb-4">
     
          <div className="d-flex justify-content-between align-items-center">
                  <label className="form-label" htmlFor="signupModalFormLoginPassword">Password</label>

                  <a className="form-label-link" href='#' onClick={(e)=> setforgot(true)} >Forgot Password?</a>
                </div>

           
     

          <div className="input-group input-group-merge" data-hs-validation-validate-classname>
            <input type="password" onChange={(e)=> setpassword(e.target.value)}  className="js-toggle-password form-control form-control-lg" name="password" id="signupSimpleLoginPassword" placeholder="********" aria-label="8+ characters required" required minLength="8"
                  data-hs-toggle-password-options='{
                   "target": "#changePassTarget",
                   "defaultclassName": "bi-eye-slash",
                   "showclassName": "bi-eye",
                   "classNameChangeTarget": "#changePassIcon"
                 }' />
            <a id="changePassTarget" className="input-group-append input-group-text" href="javascript:;">
              <i id="changePassIcon" className="bi-eye"></i>
            </a>
          </div>

          <span className="invalid-feedback">Please enter a valid password.</span>
        </div>
       

        <div className="d-grid mb-3">
          <button type="button" onClick={sendlogin} style={{backgroundColor:'#08605F'}} className="btn btn-success btn-lg" disabled={loading}>{loading?'Please Wait':'Sign in'}</button>
        </div>
        </>
      }
        <div className="text-center">
       
        </div>
      </form>
    </div>
   
   
  </div>

  

</div>

<div className="col-md-5 mb-7 mb-md-0">
      <img src='../assets/c1.png' style={{width:'100%'}}/>
      <div className='row'>

     
      </div>
    </div>
</div>

</div>





  

    </div>
  )
}
