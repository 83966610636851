import React from 'react'
import Sidebar from '../components/Sidebar'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useNavigate, Link  } from 'react-router-dom';

export default function Dashboard() {
  return (
    <div>
      <div className="navbar-dark bg-dark" style={{   backgroundImage: `url("assets/svg/components/wave-pattern-light.svg")` }} >
    <div className="container content-space-1 content-space-b-lg-3">
      <div className="row align-items-center">
        <div className="col">
          <div className="d-none d-lg-block">
            <h1 className="h2 text-white">Welcome Back!</h1>
          </div>

      
          <nav aria-label="breadcrumb">
           
          </nav>
         
        </div>
     

        <div className="col-auto">
          <div className="d-none d-lg-block">
            
          </div>

       
          <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarNav" aria-controls="sidebarNav" aria-expanded="false" aria-label="Toggle navigation">
            <div className="navbar-toggler-default">
              <i className="bi-list"></i>
            </div>
            <div className="navbar-toggler-toggled">
              <i className="bi-x"></i>
            </div>
          </button>
         
        </div>
      
      </div>
     
    </div>
  </div>
  
    <div className="container  content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
  
      <div className='row'>


        <div className="col-lg-3">
          <Sidebar/>
          
        </div>  


        <div className="col-lg-9">
          <div className="d-grid gap-3 gap-lg-5">             
          <div className="card" >
          
          <div className="card-body" >
             
          <div className="mb-5" >
                <div className="row gx-3">
                  <div className="col-sm-3 mb-3 mb-sm-0">
                  <Link  to="/reports">
                    <div className="card card-dashed shadow-none text-center rounded-2">
                      <div className="card-body">
                        <span className="svg-icon text-primary mb-3">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.302 11.35L12.002 20.55H21.202C21.802 20.55 22.202 19.85 21.902 19.35L17.302 11.35Z" fill="#035A4B"></path>
                          <path opacity="0.3" d="M12.002 20.55H2.802C2.202 20.55 1.80202 19.85 2.10202 19.35L6.70203 11.45L12.002 20.55ZM11.302 3.45L6.70203 11.35H17.302L12.702 3.45C12.402 2.85 11.602 2.85 11.302 3.45Z" fill="#035A4B"></path>
                          </svg>

                        </span>                   
                        <p className="card-text">Leads</p>
                      </div>
                    </div>
                    </Link>
                  </div>

                  <div className="col-sm-3 mb-3 mb-sm-0">
                  <Link  to="/packout">
                    <div className="card card-dashed shadow-none text-center rounded-2">
                      <div className="card-body">
                        <span className="svg-icon text-primary mb-3">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="#035A4B"></path>
                          <path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="#035A4B"></path>
                          </svg>

                        </span>

                       
                        <p className="card-text">Packout</p>
                      </div>
                    </div>
                    </Link>
                  </div>

                  <div className="col-sm-3 mb-3 mb-sm-0">
                  <Link  to="/packback">
                   <div className="card card-dashed shadow-none text-center rounded-2">
                     <div className="card-body">
                       <span className="svg-icon text-primary mb-3">
                         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="#035A4B"></path>
                         <path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="#035A4B"></path>
                         </svg>

                       </span>

                      
                       <p className="card-text">Packback</p>
                     </div>
                   </div>
                   </Link>
                 </div>


                  <div className="col-sm-3">
                  <Link  to="/ip1">
                    <div className="card card-dashed shadow-none text-center rounded-2">
                      <div className="card-body">
                        <span className="svg-icon text-primary mb-3">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15.6 5.59998L20.9 10.9C21.3 11.3 21.3 11.9 20.9 12.3L17.6 15.6L11.6 9.59998L15.6 5.59998ZM2.3 12.3L7.59999 17.6L11.6 13.6L5.59999 7.59998L2.3 10.9C1.9 11.3 1.9 11.9 2.3 12.3Z" fill="#035A4B"></path>
                          <path opacity="0.3" d="M17.6 15.6L12.3 20.9C11.9 21.3 11.3 21.3 10.9 20.9L7.59998 17.6L13.6 11.6L17.6 15.6ZM10.9 2.3L5.59998 7.6L9.59998 11.6L15.6 5.6L12.3 2.3C11.9 1.9 11.3 1.9 10.9 2.3Z" fill="#035A4B"></path>
                          </svg>

                        </span>

                
                        <p className="card-text">IP1</p>
                      </div>
                    </div>
                    </Link>
                  </div>
                </div>
          
              </div>
             


              </div> 
          </div> 
          </div>  
        </div> 
      </div>
      </div> 


    </div>     
  
  
  )
}
