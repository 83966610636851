import React, {useState, useEffect } from 'react'
import axios from 'axios';
import Sidebar from '../components/Sidebar'
import { useNavigate, Link , useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function Introducerusers() {

  const [admindata, setadmindata]= useState(null);

  const { introid } = useParams();

  const [dataloading, setdataloading]= useState(false);

  const [loading, setloading]= useState(false);
  const [createsuccess, setcreatesuccess]= useState(false);
  const [editsuccess, seteditsuccess]= useState(false);
  const [deletesuccess, setdeletesuccess]= useState(false);
  const [mismatch, setmismatch]= useState(false);
  
  const [name, setname]= useState(null);
  const [email, setemail]= useState(null);
  const [password, setpassword]= useState(null);
  const [admin, setadmin]= useState(null);
  const [accountID, setaccountID]= useState(null);

    console.log(introid);

  const navigate = useNavigate();

  const logindata = JSON.parse(localStorage.getItem('login'));

  const config = {
    headers: { Authorization: `Bearer ${logindata.token}` }
  };   
   

  useEffect(() => {
    console.log("working");
    setdataloading(true);
        axios.get(`https://clearstart-api.clientmaster.co.uk/api/introducers/cs-admin/`+introid+`/users/`,config).then((response) => {  
          setadmindata(response.data.data);
          console.log(response.data.data);
          setdataloading(false);
      }); 


  
  },[CreateNewaccount,EditAccountsave,DeleteUser])

  
  const CreateNewaccount = () =>{     
    console.log('Create New Account');  
    if(email)
    {   
        setloading(true);  
        axios.post('https://clearstart-api.clientmaster.co.uk/api/introducers/cs-admin/'+introid+'/users/',{name,email,password,admin},config).then((response) =>{   
            let userdata = JSON.stringify(response.data);       
            console.log(userdata);
            setcreatesuccess(true);
            setloading(false);

            setname(null);
            setemail(null);  
            setadmin(null); 
            setpassword(null);


        }).catch((error) => {  
            setloading(false);
            setcreatesuccess(false);
            alert('session timeout please re-login and try again')        
           
          });       
    }
    else
    {
      
        setmismatch(true);
    }

  }

 

  const EditAccount =(accountID,name,email,admin) => {
    setaccountID(accountID);
    setname(name);
    setemail(email);  
    setadmin(admin);
}  

const EditAccountsave = () =>{     
  console.log('Edit Account');  
  if(email)
  {   
      setloading(true);  
      axios.post('https://clearstart-api.clientmaster.co.uk/api/introducers/cs-admin/'+introid+'/users/'+accountID+'/',{name,email,password,admin},config).then((response) =>{   
          let userdata = JSON.stringify(response.data);       
          console.log(userdata);
          seteditsuccess(true);
          setloading(false);
          setaccountID(null);
          setname(null);
          setemail(null);  
          setadmin(null); 
          setpassword(null);
      }).catch((error) => {  
          setloading(false);
          seteditsuccess(false);
          alert('session timeout please re-login and try again')        
         
        });       
  }
  else
  {
      //password missmatch
      setmismatch(true);
  }

}


const DeleteUser = (accountid) =>{     
  console.log('Delete Account');  

if(window.confirm('Are you sure you want to delete this user?')) {
  if(accountid)
  {   
      setloading(true);  
      axios.post('https://clearstart-api.clientmaster.co.uk/api/introducers/cs-admin/'+introid+'/users/'+accountid+'/',{remove_account:'true'},config).then((response) =>{   
 
        setdeletesuccess(true);
          setloading(false);
          setaccountID(null);

     
      }).catch((error) => {  
          setloading(false);
          setdeletesuccess(false);
          alert('session timeout please re-login and try again')        
         
        });       
  }
  else
  {
      //password missmatch
      setmismatch(true);
  }
}
}


  return (
    <div>

    <div className="navbar-dark bg-dark" style={{   backgroundImage: `url("assets/svg/components/wave-pattern-light.svg")` }} >
          <div className="container content-space-1 content-space-b-lg-3">
            <div className="row align-items-center">
              <div className="col">
                <div className="d-none d-lg-block">
                  <h1 className="h2 text-white">Introducer User Manager</h1>
                </div>
    
            
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-light mb-0">
                    <li className="breadcrumb-item"> Manage Introducers</li>
                   
                  </ol>
                </nav>
               
              </div>
           
    
              <div className="col-auto">
                <div className="d-none d-lg-block">
                 
                </div>
    
             
                <button className="navbar-toggler d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarNav" aria-controls="sidebarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <div className="navbar-toggler-default">
                    <i className="bi-list"></i>
                  </div>
                  <div className="navbar-toggler-toggled">
                    <i className="bi-x"></i>
                  </div>
                </button>
               
              </div>
            
            </div>
           
          </div>
        </div>
        <div className="container  content-space-1 content-space-t-lg-0 content-space-b-lg-2 mt-lg-n10">
          <div className='row'>
    
    
            <div className="col-lg-3">
            <Sidebar />
            </div> 

        <div className="col-lg-9">
          <div className="d-grid gap-3 gap-lg-5">
          <div className="card">
              <div className="card-header border-bottom">
              <button type="button" class="btn btn-xs btn-primary" style={{float:"right"}} data-bs-toggle="modal" data-bs-target="#createuser">
            Create New User
            </button>
                <h4 className="card-header-title">List of Introducers</h4>
               
              </div>             
             

        
              {createsuccess? <>
                <div className="alert alert-success" role="alert">
                 New User Account Added
                </div>
                </>: <></>}

                {editsuccess? <>
                <div className="alert alert-success" role="alert">
                User Account Modified Successfully
                </div>
                </>: <></>}

                {deletesuccess? <>
                <div className="alert alert-success" role="alert">
                User Removed Successfully
                </div>
                </>: <></>}

                    
              <div className="table-responsive">
              {dataloading?<><Skeleton count={8} /> </>              
              :<>
                <table className="table table-borderless table-thead-bordered table-nowrap table-align-middle">
                  <thead className="thead-light">
                    <tr>
                      <th>ID</th>
                      <th>Name</th>                      
                      <th>Email</th>             
                      <th>Status</th>             
                      <th></th>      
                      <th></th>                   
                    
                    </tr>
                  </thead>
           
                   <tbody>

             {admindata?<>
              {admindata.map(data => {                        
                      return (          
                    <tr key={data.uid} >
                    <td>{data.accountid}</td> 
                      <td>{data.name}</td>           
                      <td>{data.email}</td>        
                      <td>{data.active}</td>               
                      <td>
                      <a class="btn btn-white btn-xs" href="#" onClick={() => EditAccount(data.accountid, data.name, data.email,data.admin)} data-bs-toggle="modal" data-bs-target="#exampleModal">
                        <i class="bi-pencil-fill me-1"></i> Edit
                      </a>
                      <a class="btn btn-white btn-xs" style={{marginLeft:'10px'}} href="#" onClick={ () => DeleteUser(data.accountid)}>
                        <i class="bi-trash"></i> Delete
                      </a>
                        </td>                 
                      
                    </tr>
                    
                    )
                  })} 
                  </>:<></>}
                  
                  </tbody>      
                       
                </table> 
                </> }                
              </div>
              

<div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <div className='mb-2'>
            <label className="form-label" for="exampleFormControlInput1">Name</label>
            <input className="form-control" value={name}  onChange={(e)=> setname(e.target.value)}  type="text" placeholder="Enter Name"/>
        </div>
        <div className='mb-2'>
              <label className="form-label" for="exampleFormControlInput1">Email Address</label>
            <input className="form-control" value={email}  onChange={(e)=> setemail(e.target.value)}  type="text" placeholder="Email Address"/>
        </div>
        <div className='mb-2'>
              <label className="form-label" for="exampleFormControlInput1">Password</label>
            <input className="form-control"  onChange={(e)=> setpassword(e.target.value)}  type="text" placeholder="Password"/>
        </div>
        <div className='mb-2'>
              <label className="form-label" for="exampleFormControlInput1">Select Account Type</label>
              <select class="form-select"  onChange={(e)=> setadmin(e.target.value)} >
                <option value="">Select Account Type</option>
                <option value="true" selected={admin == true}>Admin</option>
                <option value="false" selected={admin == false}>User</option>               
              </select>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-white" data-bs-dismiss="modal">Close</button>
        <button type="button" onClick={EditAccountsave} className="btn btn-primary" data-bs-dismiss="modal">Save changes</button>
      </div>
    </div>
  </div>
</div>



<div className="modal fade" id="createuser" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Create New User</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <div className='mb-2'>
            <label className="form-label" for="exampleFormControlInput1">Name</label>
            <input className="form-control"  onChange={(e)=> setname(e.target.value)}  type="text" placeholder="Enter Name"/>
        </div>
        <div className='mb-2'>
              <label className="form-label" for="exampleFormControlInput1">Email Address</label>
            <input className="form-control"  onChange={(e)=> setemail(e.target.value)}  type="text" placeholder="Email Address"/>
        </div>
        <div className='mb-2'>
              <label className="form-label" for="exampleFormControlInput1">Password</label>
            <input className="form-control"  onChange={(e)=> setpassword(e.target.value)}  type="text" placeholder="Password"/>
        </div>
        <div className='mb-2'>
              <label className="form-label" for="exampleFormControlInput1">Select Account Type</label>
              <select class="form-select"  onChange={(e)=> setadmin(e.target.value)} >
                <option value="">Select Account Type</option>
                <option value="true">Admin</option>
                <option value="false">User</option>               
              </select>
        </div>
                
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-white" data-bs-dismiss="modal">Close</button>
        <button type="button"  onClick={CreateNewaccount} className="btn btn-primary" data-bs-dismiss="modal">Create New User</button>
      </div>
    </div>
  </div>
</div>

              </div>

            
          </div>
        </div> 
      </div>

    </div>     

     
  </div>
  )
}
