import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const AuthRoutes = () => {
   
    const login = localStorage.getItem('login');    

   
  
   // console.log(login)
    let auth = null;
    if(login)
    {
        auth = true;
    }

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return auth ? <Outlet  /> : <Navigate to="/login" />;
}
export default AuthRoutes